import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";

import { getDate } from "../fireBase/fetchFirebase";
import WorkSingel from "./WorkSingel";

function Wd() {
  const [cat, setCat] = useState("Du nouveau à l'ancien");

  const [data, setData] = useState(null);
  const [filterdData, setFilterdData] = useState(null);
  const [filterd, setFilterd] = useState(null);
  ////////////////////////////////////////////////
  const fetchItems = async () => {
    await getDate().then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    fetchItems();
    // window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    if (data) {
      setFilterd(data.filter((ele) => ele.category === "Web development"));
    }
  }, [data]);

  const change = (e) => {
    const tar = e.target.value;
    setCat(tar);
    if (filterd) {
      if (tar === "Du nouveau à l'ancien") {
        setFilterdData(filterd);
      } else if (tar === "De l'ancien au nouveau") {
        const dd = filterd.map((ele) => ele);
        setFilterdData(dd.reverse());
      } else if (tar === "A_Z") {
        const dd = filterd.map((ele) => ele);
        setFilterdData(dd.sort((a, b) => (a.title > b.title ? 1 : -1)));
      } else if (tar === "Z_A") {
        const dd = filterd.map((ele) => ele);
        setFilterdData(dd.sort((a, b) => (a.title < b.title ? 1 : -1)));
      }
    }
  };
  useEffect(() => {
    if (filterd) {
      setFilterdData(filterd);
    }
  }, [filterd]);

  return (
    <>
      <AnimatePresence>
        {filterd && (
          <motion.div
            transition={{ duration: 1, ease: "easeInOut" }}
            initial={{ opacity: 0.5, x: "100%" }}
            animate={{ opacity: 1, x: "0" }}
            exit={{ opacity: 0.5, x: "-100%" }}
            className="w-full"
          >
            <div className="w-full flex justify-center md:justify-between  md:gap-0 gap-5 items-center  flex-wrap mb-16">
              <p className="text-xl text-slate-500">
                Nombre de projetss{" "}
                <span className="text-black font-bold">{`(${filterd.length})`}</span>
              </p>
              <div className="flex gap-4 w-fit justify-end items-center">
                <p className="text-xl font-bold">Filtrer :</p>
                <select
                  onChange={(e) => change(e)}
                  value={cat}
                  name=""
                  id=""
                  className="  w-[200px] cursor-pointer text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block  px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="Du nouveau à l'ancien">
                    Du nouveau à l'ancien
                  </option>
                  <option value="De l'ancien au nouveau">
                    De l'ancien au nouveau
                  </option>
                  <option value="A_Z">A_Z</option>
                  <option value="Z_A">Z_A</option>
                </select>
              </div>
            </div>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {filterdData &&
                filterdData.map((ele) => {
                  return <WorkSingel data={ele} key={ele.id} />;
                })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default Wd;
