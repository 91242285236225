// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC3TdwoDTsObw0FZv7rmDQm5XRjDkGHMxQ",
  authDomain: "digitalgarphic-4dcc4.firebaseapp.com",
  projectId: "digitalgarphic-4dcc4",
  storageBucket: "digitalgarphic-4dcc4.appspot.com",
  messagingSenderId: "345445096021",
  appId: "1:345445096021:web:eb4a9192f267469d2ed2dc",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const storage = getStorage(app);

export { app, db, storage };
