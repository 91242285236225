import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import "./style.css";
import { about1, about2, about3 } from "../assets/about/index";
import Aos from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";

const data = [
  {
    id: 1,
    img: about1,
    par: "Nous combinons notre expertise numérique et notre vision globale pour aider les marques à maximiser leur potentiel grâce au marketing numérique. Nous concevons, implémentons et supervisons des stratégies numériques sur une multitude de canaux afin de garantir une présence optimale en ligne.",
    titel: "About us",
    ul: false,
    cart: false,
  },
  {
    id: 2,
    img: about2,
    par: "En tant qu'agence de conception graphique et de développement Web, nous travaillons à améliorer les aspects visuels des entreprises de nos clients en créant des supports de marque et de conception convaincants, tels que des logos, des brochures et des sites Web. Notre processus implique généralement de comprendre les buts et objectifs de nos clients, de rechercher les tendances de l'industrie et de réfléchir à des idées de conception créatives. Nous créons ensuite des maquettes et des prototypes que nos clients peuvent examiner et commenter, avant de passer à la conception finale et au développement. Tout au long du processus, nous maintenons une communication ouverte avec nos clients pour nous assurer que leur vision se réalise et que leurs besoins sont satisfaits.",
    titel: "Comment travaillons-nous",
    ul: false,
    cart: true,
  },
  {
    id: 3,
    img: about3,
    par: "Pourquoi choisir  Digital graphic ?",
    titel: "L'AMBITION EST NOTRE FORCE !",
    ul: true,
    cart: false,
  },
];
function About() {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="h-[100vh] overflow-hidden">
      <Swiper
        direction={"vertical"}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        {data.map((ele) => {
          return (
            <SwiperSlide key={ele.id}>
              <div className=" relative w-full h-full">
                <div className=" absolute top-0 left-0 w-full h-full z-10 bg-[#00000061]"></div>

                <img
                  src={ele.img}
                  alt=""
                  className="w-full h-full hh rounded-none"
                />
                <div className=" absolute top-0 left-0 z-50 w-full h-full gap-5 flex flex-col justify-center items-center  px-3 md:px-9">
                  <motion.h1
                    key={11}
                    transition={{ duration: 1.5, ease: "easeInOut" }}
                    initial={{ opacity: 0, scale: 1.5 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    className="text-3xl  sm:text-6xl w-fit pb-5 mb-[30px] mx-auto text-white font-bold relative before:w-[50%] before:absolute before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:h-[4px] before:bg-scolor"
                  >
                    {ele.titel}
                  </motion.h1>
                  <motion.p
                    key={12}
                    transition={{ duration: 1.5, ease: "easeInOut" }}
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    className={`  ${
                      ele.cart
                        ? "text-lg lg:text-xl text-center leading-normal max-w-[650px] text-white "
                        : "text-center text-xl text-white leading-loose max-w-[600px]"
                    }`}
                  >
                    {ele.par}
                  </motion.p>
                  {ele.ul && (
                    <motion.ul
                      key={22}
                      transition={{ duration: 1.5, ease: "easeInOut" }}
                      initial={{ opacity: 0, scale: 1.5 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      className="flex flex-col gap-5 text-lg text-start font-semibold text-white"
                    >
                      <li className=" w-full ps-7 relative before:absolute before:w-[15px] before:h-[15px] before:rounded-full before:bg-scolor before:left-0 before:top-[50%] before:translate-y-[-50%]">
                        Vous rêvez, nous réalisons
                      </li>
                      <li className=" ps-7 w-full relative before:absolute before:w-[15px] before:h-[15px] before:rounded-full before:bg-scolor before:left-0 before:top-[50%] before:translate-y-[-50%]">
                        Nous simplifions les tâches
                      </li>
                      <li className=" w-full ps-7 relative before:absolute before:w-[15px] before:h-[15px] before:rounded-full before:bg-scolor before:left-0 before:top-[50%] before:translate-y-[-50%]">
                        Transparence et probité
                      </li>
                      <li className=" ps-7  relative before:absolute before:w-[15px] before:h-[15px] before:rounded-full before:bg-scolor before:left-0 before:top-[50%] before:translate-y-[-50%]">
                        Nous tenons nos promesses
                      </li>
                    </motion.ul>
                  )}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

export default About;
