import React, { useState } from "react";
import { FaArrowUp } from "react-icons/fa";

function ToUp() {
  const [up, setUp] = useState(false);
  window.addEventListener("scroll", (e) => {
    if (window.scrollY >= 1000) {
      setUp(true);
    } else {
      setUp(false);
    }
  });
  return (
    <div
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      className={`md:w-[40px] md:h-[40px] h-[35px] w-[35px] cursor-pointer  justify-center items-center rounded-xl z-[10000] bg-scolor jello-horizontal fixed bottom-[50px] md:bottom-[80px] right-[30px] md:right-[50px] ${
        up ? "flex" : "hidden"
      }  `}
    >
      <FaArrowUp className="text-2xl text-white" />
    </div>
  );
}

export default ToUp;
