import React from "react";
import d from "../assets/d.png";
import { RiInstagramFill } from "react-icons/ri";
import { MdFacebook } from "react-icons/md";
import { NavLink } from "react-router-dom";
function Footer() {
  return (
    <>
      <div className="px-3 md:px-9 bg-fcolor py-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
          <div>
            <div className="flex items-center group gap-[2px] justify-center mb-5 ">
              <div className=" w-[70px]">
                <img
                  src={d}
                  alt="logo"
                  className="w-full h-full object-cover"
                />
              </div>
              <h1 className={"text-3xl font-semibold  text-scolor "}>
                igital Graphic
              </h1>
            </div>
            <p className="text-lg text-white text-center">
              Nous sommes une agence professionnelle de graphisme et de
              développement web, proposant des solutions innovantes pour
              valoriser l'identité visuelle et la présence en ligne de votre
              marque. Faites confiance à notre expertise pour créer des designs
              captivants et des sites Web conviviaux.
            </p>
          </div>
          <div>
            <h1 className="text-3xl text-scolor font-semibold  h-[56px] w-fit mx-auto flex items-center mb-5">
              DIT BONJOUR
            </h1>
            <p className="text-lg text-white text-center">
              Si vous êtes intéressé à travailler avec nous ou si vous voulez
              simplement nous dire bonjour, envoyez-nous un message
            </p>
            <p className="text-lg text-scolor text-center font-semibold mt-3">
              digitalgraphicdz@gmail.com
            </p>
          </div>
          <div>
            <h1 className="text-3xl text-scolor font-semibold  h-[56px] w-fit mx-auto flex items-center mb-5">
              RESTEZ CONNECTÉ
            </h1>
            <p className="text-lg text-white text-center mt-3">
              suivez-nous sur les réseaux sociaux:
            </p>
            <ul className="flex flex-col gap-3 mt-3 items-center">
              <li className="w-fit">
                <a
                  href="https://www.facebook.com/profile.php?id=100064091171195&mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center gap-3 text-white text-2xl transition-all duration-300 ease-in-out hover:text-scolor"
                >
                  <MdFacebook className="text-[25px]" />
                  <span>Facebook</span>
                </a>
              </li>
              <li className="w-fit">
                <a
                  href="https://instagram.com/digitalgraphe?igshid=MWZjMTM2ODFkZg=="
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center gap-2 text-white text-2xl transition-all duration-300 ease-in-out hover:text-scolor"
                >
                  <RiInstagramFill />
                  <span>Instagram</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="px-3 md:px-9 bg-scolor py-6 ">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 ">
          <div>
            <ul className="flex gap-4 items-center lg:justify-start justify-center flex-wrap text-white md:text-xl text-lg font-semibold">
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "text-fcolor"
                      : "transition-all duration-300 ease-in-out hover:text-fcolor"
                  }
                  to="/"
                >
                  Accueil
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "text-fcolor"
                      : "transition-all duration-300 ease-in-out hover:text-fcolor"
                  }
                  to="Services"
                >
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "text-fcolor"
                      : "transition-all duration-300 ease-in-out hover:text-fcolor"
                  }
                  to="Work/All"
                >
                  Projets
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "text-fcolor"
                      : "transition-all duration-300 ease-in-out hover:text-fcolor"
                  }
                  to="About"
                >
                  À propos de nous
                </NavLink>
              </li>
              <li>
                <NavLink
                  // className="transition-all duration-300 ease-in-out hover:text-fcolor"
                  className={({ isActive }) =>
                    isActive
                      ? "text-fcolor"
                      : "transition-all duration-300 ease-in-out hover:text-fcolor"
                  }
                  to="Contact"
                >
                  Contactez-nous
                </NavLink>
              </li>
            </ul>
          </div>
          <p className="w-full text-center lg:text-end text-lg font-semibold text-fcolor">
            Designed by Digital Graphic💗 ©Copyright{new Date().getFullYear()}{" "}
            All rights resrved
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
