import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { getDate } from "../fireBase/fetchFirebase";
import SingelProject from "./SingelProject";
function RecentProjects() {
  const [data, setData] = useState(null);
  const [resent, setResent] = useState(null);

  useEffect(() => {
    if (data) {
      setResent(data.slice(0, 5));
    }
  }, [data]);
  const fetchItems = async () => {
    await getDate().then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
    fetchItems();
  }, []);
  return (
    <div className="w-full min-h-[100vh] pt-[50px] lg:mb-0 mb-[100px]  px-3 md:px-9 overflow-hidden">
      <h1
        data-aos="zoom-out"
        className="text-3xl sm:text-5xl w-fit pb-5 mb-[80px] mx-auto text-fcolor font-bold relative before:w-[50%] before:absolute before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:h-[4px] before:bg-scolor "
      >
        Projets Récents
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {resent &&
          resent.map((ele) => {
            return <SingelProject data={ele} key={ele.id} />;
          })}
      </div>
      <Link
        to="Work/all"
        data-aos="zoom-in"
        className="flex mt-10 w-[200px] font-bold text-lg h-[50px] items-center justify-center mx-auto rounded-xl bg-fcolor text-white fontb transition-all duration-300 ease-in-out  hover:bg-scolor"
      >
        VOIR PLUS ...
      </Link>
    </div>
  );
}

export default RecentProjects;
