import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getDate } from "../fireBase/fetchFirebase";
import SingelProject from "./SingelProject";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { MutatingDots } from "react-loader-spinner";
import Aos from "aos";
import "aos/dist/aos.css";
import "./st.css";
import { FaArrowLeft } from "react-icons/fa";
function ProductInfo() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [pro, setPro] = useState();
  const [img, setImg] = useState("");
  const [test, setTest] = useState();
  const [reletiv, setReletiv] = useState(null);
  const [over, setOver] = useState(false);
  const [imgs, setImgs] = useState([]);
  const fullscreenRef = useRef(null);
  const slideshowRef = React.useRef(null);
  const zoomRef = React.useRef(null);
  const thumbnailsRef = React.useRef(null);
  useEffect(() => {
    setTest("blur-lg");
    setTimeout(() => {
      setTest("blur-none");
    }, 700);
  }, [img]);
  useEffect(() => {
    setImgs([]);
    if (pro) {
      pro.allImg.map((ele) => {
        return setImgs((prev) => [...prev, { src: ele }]);
      });
    }
  }, [pro]);
  const fetchItems = async () => {
    await getDate().then((res) => {
      setData(res);
    });
  };
  useEffect(() => {
    fetchItems();
  }, []);
  useEffect(() => {
    fetchItems();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);
  useEffect(() => {
    if (data) {
      setPro(data.find((ele) => Number(ele.id) === Number(id)));
    }
  }, [data]);

  useEffect(() => {
    if (pro) {
      setImg(pro.mainImg);
      setReletiv(
        data
          .filter((ele) => ele.category === pro.category && ele.id !== pro.id)
          .slice(0, 5)
      );
    }
  }, [pro]);

  const cli = (ele) => {
    setImg(ele);
  };
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      {pro ? (
        <div className=" pb-20 bg-black px-3 md:px-9 relative overflow-hidden">
          {/* {over && (
            <div className="w-full h-full fixed z-[10000] top-0 left-0"></div>
          )} */}
          <Lightbox
            open={over}
            close={() => setOver(false)}
            slides={imgs}
            plugins={[Fullscreen, Slideshow, Zoom, Captions, Thumbnails]}
            fullscreen={{ ref: fullscreenRef }}
            slideshow={{ ref: slideshowRef }}
            zoom={{ ref: zoomRef }}
            inline={{
              style: { width: "100%", maxWidth: "900px", aspectRatio: "3 / 2" },
            }}
            on={{
              click: () => {
                (slideshowRef.current?.playing
                  ? slideshowRef.current?.pause
                  : slideshowRef.current?.play)?.();
                fullscreenRef.current?.enter();
                (thumbnailsRef.current?.visible
                  ? thumbnailsRef.current?.hide
                  : thumbnailsRef.current?.show)?.();
              },
            }}
          />
          <div className="w-full min-h-[80vh] md:h-fit lg:h-[120vh] pt-[100px] flex flex-wrap justify-between md:gap-0 gap-7">
            <div className="w-full h-[75%] order-1  lg:w-[48%] rounded-2xl overflow-hidden cursor-zoom-in">
              <img
                onClick={() => setOver(true)}
                src={img}
                alt=""
                className={`w-full h-full object-fill transition-all duration-200 ease-in-out ${test}`}
              />
            </div>
            <div className="w-full h-[75%] lg:w-[48%] flex flex-col gap-10 order-3 md:order-2">
              <h1 className="text-center font-bold text-scolor text-5xl mb-5">
                {pro.title}
              </h1>
              <div className="flex items-center md:justify-between justify-center flex-wrap ">
                <p className="text-2xl text-white font-semibold mb-3 md:mb-0">
                  catégorie de projet :
                </p>
                <p className="text-scolor font-bold text-3xl">{pro.category}</p>
              </div>
              <div>
                <p className="text-2xl text-white font-semibold mb-4">
                  description du projet :
                </p>
                <p className="text-lg leading-loose text-white">
                  - {pro.discription}
                </p>
              </div>
              <button
                onClick={() => navigate(-1)}
                className="flex justify-center mx-auto rounded-3xl gap-2 items-center h-[60px] w-[200px] text-white bg-scolor text-xl font-bold "
              >
                <FaArrowLeft className="text-lg" />
                RETOUR
              </button>
            </div>
            <div className=" w-[100%]  mx-auto overflow-x-auto mt-0 md:mat-7 order-2 sc md:order-3">
              <div className=" w-fit py-10  flex gap-6 justify-center items-center mx-auto ">
                {pro.allImg.map((ele, i) => {
                  return (
                    <div
                      key={i}
                      className={`w-[80px] md:w-[120px] cursor-pointer h-[80px] md:h-[120px] rounded-xl overflow-hidden transition-all duration-200 ease-in-out ${
                        ele === img ? "border-4 border-scolor " : ""
                      }`}
                      onClick={() => cli(ele)}
                    >
                      <img
                        src={ele}
                        className="w-full h-full object-cover"
                        alt=""
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="w-full min-h-[50vh]  mt-20">
            <h1 className="text-2xl sm:text-4xl w-fit pb-5 mx-auto text-white font-bold relative before:w-[50%] before:absolute before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:h-[4px] before:bg-scolor ">
              Projets Dans La Même Catégorie
            </h1>
            <div className="mt-[80px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {reletiv &&
                reletiv.map((ele) => {
                  return <SingelProject data={ele} key={ele.id} />;
                })}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-[100vh] flex justify-center items-center">
          <MutatingDots
            height="100"
            width="100"
            color="#00203fff"
            secondaryColor="#D9A21B"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </>
  );
}

export default ProductInfo;
