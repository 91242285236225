import React, { useEffect } from "react";
import {
  Hero,
  Features,
  FirstAdverb,
  RecentProjects,
  WhatweDo,
  SecondAdverb,
} from "../components/index";
function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="w-full h-auto">
      <Hero />
      <Features />
      <FirstAdverb />
      <RecentProjects />
      <WhatweDo />
      <SecondAdverb />
    </div>
  );
}

export default Home;
