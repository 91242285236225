import React, { useEffect } from "react";
import firstAdverb from "../assets/7088577_3506328.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
function FirstAdverb() {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);
  return (
    <div className="w-full h-[60vh]  px-3 md:px-9 relative overflow-hidden ">
      <div className=" absolute top-0 left-0 w-full h-full bg-[#00000078] z-10"></div>
      <div className=" absolute top-0 left-0 w-full h-full">
        <img
          data-aos="zoom-in"
          src={firstAdverb}
          alt="first Adverb"
          className="w-full h-full object-cover object-right"
        />
      </div>
      <div className="w-full h-full relative z-10 flex justify-center items-center">
        <q className="text-[48px] leading-[70px] text-white text-center lg:text-start">
          Bonne renommée vaut mieux que ceinture dorée
        </q>
      </div>
    </div>
  );
}

export default FirstAdverb;
