import React, { useEffect } from "react";
import banner2 from "../assets/banner2.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
function SecondAdverb() {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);
  return (
    <div className="w-full h-[60vh]  px-3 md:px-9 relative  overflow-hidden">
      <div className=" absolute top-0 left-0 w-full h-full bg-[#00000078] z-10"></div>
      <div className=" absolute top-0 left-0 w-full h-full">
        <img
          data-aos="zoom-out"
          src={banner2}
          alt="first Adverb"
          className="w-full h-full object-cover object-center"
        />
      </div>
      <div className="w-full h-full relative z-10 flex justify-center items-center">
        <q className="text-[48px] leading-[70px] text-white text-center">
          Ne demandez pas ce que votre client peut faire pour vous mais ce que
          vous pouvez faire pour vos clients.
        </q>
      </div>
    </div>
  );
}

export default SecondAdverb;
