import React from "react";
import { Link } from "react-router-dom";

function SingelProject({ data }) {
  return (
    <Link
      to={`/productInfo/${data.id}`}
      key={data.id}
      data-aos="zoom-out-left"
      className="w-full border shadow-xl h-[350px] relative group cursor-pointer rounded-xl transition-all duration-300 hover:drop-shadow-xl"
    >
      <div className="w-full h-full relative overflow-hidden rounded-xl">
        <img src={data.mainImg} alt="" className="w-full h-full object-fill" />
        <img
          src={data.mainImg}
          alt=""
          className=" scale-125 object-cover w-full h-full transition-all duration-300 ease-in-out absolute top-0 left-0 -z-50 opacity-0 group-hover:z-50 group-hover:opacity-100"
        />
        <div className=" absolute top-0 sm:right-[-100%] right-0  p-3 bg-[#ffffffc9] w-full h-fit z-0 sm:-z-50 opacity-100 sm:opacity-0 group-hover:opacity-100 group-hover:z-[200] group-hover:right-0 transition-all duration-500 ease-in-out">
          <p className=" text-3xl font-bold text-scolor  text-center ">
            {data.category}
          </p>
        </div>
        <div className=" absolute bottom-0 sm:left-[-100%] left-0 p-3 bg-[#ffffffc9] w-full h-fit z-0 sm:-z-50  sm:opacity-0 group-hover:opacity-100 group-hover:z-[200] group-hover:left-0 transition-all duration-500 ease-in-out">
          <p className=" text-3xl font-bold text-scolor  text-center ">
            {data.title}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default SingelProject;
