import React, { useEffect } from "react";
import servp from "../assets/services/servicesp.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import site1 from "../assets/service/site1.jpg";
import site2 from "../assets/service/site2.jpg";
import sm2 from "../assets/service/sm2.jpg";
import socialMedia1 from "../assets/service/socialMedia.jpg";
import gd1 from "../assets/service/gd1.jpg";
import gd2 from "../assets/service/gd2.jpg";
import cm1 from "../assets/service/cm1.jpg";
import cm2 from "../assets/service/cm2.jpg";
import { ToUp } from "../components/index";
import { Link as Li } from "react-scroll";
function Services() {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className=" overflow-hidden pb-20">
      <ToUp />
      <div className=" w-full   flex items-center relative overflow-hidden h-[60vh] mb-[100px]">
        <div className=" absolute top-0 left-0 w-full h-full z-10 bg-[#0000007a]"></div>
        <div className="flex items-center justify-center px-3 md:px-9 flex-col gap-5  pt-[70px] absolute top-0 left-0 w-full h-full z-20">
          <h1
            data-aos="zoom-out"
            className="text-3xl sm:text-7xl w-fit pb-5  mx-auto text-white font-bold relative before:w-[50%] before:absolute before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:h-[4px] before:bg-scolor "
          >
            NOS SERVICES
          </h1>
          <p
            data-aos="zoom-in"
            className="text-center max-w-[600px] text-white text-lg leading-relaxed font-semibold"
          >
            Nous concevons des produits marketing et numériques d'une utilité
            exceptionnelle et d'une beauté remarquable.
          </p>
          <ul className="flex md:mt-5 justify-center gap-5 md:gap-10 flex-wrap items-center text-md md:text-lg font-semibold ">
            <li>
              <Li
                className="text-scolor cursor-pointer transition-all duration-200 ease-in-out hover:text-white"
                activeClass="active"
                to="1"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
                // onSetActive={this.handleSetActive}
              >
                Développement Web
              </Li>
            </li>
            <li>
              <Li
                className="text-scolor cursor-pointer transition-all duration-200 ease-in-out hover:text-white"
                to="2"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
                // onSetActive={this.handleSetActive}
              >
                Conception Graphique
              </Li>
            </li>
            <li>
              <Li
                className="text-scolor cursor-pointer transition-all duration-200 ease-in-out hover:text-white"
                activeClass="active"
                to="3"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
                // onSetActive={this.handleSetActive}
              >
                Réseaux Sociaux
              </Li>
            </li>
            <li>
              <Li
                className="text-scolor cursor-pointer transition-all duration-200 ease-in-out hover:text-white"
                activeClass="active"
                to="4"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
                // onSetActive={this.handleSetActive}
              >
                Maintenance Informatique
              </Li>
            </li>
          </ul>
        </div>
        <img src={servp} alt="" className="w-full h-full object-cover" />
      </div>
      <h1
        id="1"
        data-aos="zoom-out"
        className="text-3xl sm:text-5xl w-fit pb-5 mb-[100px] mx-auto text-fcolor font-bold relative before:w-[35%] before:absolute before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:h-[4px] before:bg-scolor "
      >
        Développement Web
      </h1>
      <div
        data-aos="fade-up"
        className="flex flex-wrap mb-[70px] items-start md:items-center px-3 md:px-9"
      >
        <div data-aos="fade-right" className="w-full  md:w-[47%] mb-7 md:mb-0">
          <img src={site1} alt="" className="w-full h-full  rounded-2xl" />
        </div>
        <div
          data-aos="fade-left"
          className="w-full md:w-[53%] px-5 pt-0 md:pt-3"
        >
          <h2 className=" text-xl md:text-start text-center md:text-2xl  font-bold mb-10 text-scolor w-fit pb-3 relative before:absolute before:w-[50%] before:h-[5px] before:rounded-3xl  before:bottom-0 before:left-[50%] before:translate-x-[-50%]  md:before:translate-x-0 md:before:left-0 before:bg-fcolor">
            Pourquoi avoir un site web est important ?
          </h2>
          <p className="text-lg leading-relaxed text-center md:text-start  text-slate-600">
            Avoir un site web et une stratégie de présence en ligne vous permet
            d’exposer votre entreprise en ligne. Un site web est également
            important car il permet d’établir la crédibilité et de concevoir
            l’image et la réputation de votre entreprise en ligne. Un site Web
            donne non seulement de la crédibilité, mais il contribue également à
            donner une impression positive que votre entreprise est plus grande
            et plus prospère
          </p>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="flex items-start md:items-center flex-wrap mb-[70px] px-3 md:px-9"
      >
        <div
          data-aos="fade-right"
          className="w-full md:w-[53%] px-5 pt-3 mb-7 md:mb-0"
        >
          <p className="text-lg leading-relaxed text-center md:text-start  text-slate-800">
            a création de sites Internet est notre passion. Des ingénieurs et
            des spécialistes informatiques professionnels sont en mesure
            d'effectuer des tâches de toute complexité. Des solutions efficaces
            pour la satisfaction de nos clients constituent des normes
            fondamentales dans tout ce que nous faisons. Les domaines de notre
            expertise technique comprennent:
          </p>
          <ul className=" flex flex-col gap-3 ps-8 mt-5 text-lg">
            <li className="text-fcolor relative before:absolute before:w-[12px] before:h-[12px] before:rounded-full before:bg-scolor before:left-[-20px] before:top-[50%] before:translate-y-[-50%] ">
              Développement de site Internet classiques
            </li>
            <li className="text-fcolor relative before:absolute before:w-[12px] before:h-[12px] before:rounded-full before:bg-scolor before:left-[-20px] before:top-[50%] before:translate-y-[-50%] ">
              développez des sites Web avec un design moderne
            </li>
            <li className="text-fcolor relative before:absolute before:w-[12px] before:h-[12px] before:rounded-full before:bg-scolor before:left-[-20px] before:top-[50%] before:translate-y-[-50%] ">
              Responsive Dsign pour tous les appariels
            </li>
            <li className="text-fcolor relative before:absolute before:w-[12px] before:h-[12px] before:rounded-full before:bg-scolor before:left-[-20px] before:top-[50%] before:translate-y-[-50%] ">
              Site E-Commerce et boutiques en ligne
            </li>
          </ul>
        </div>
        <div data-aos="fade-left" className="w-full md:w-[47%]">
          <img src={site2} alt="" className="w-full h-full   rounded-2xl" />
        </div>
      </div>
      <Link
        data-aos="zoom-in"
        to="/Work/WebDevelopment"
        className="flex w-[200px] mb-[100px] h-[50px] rounded-3xl mx-auto uppercase mt-6 justify-center items-center text-xl font-bold bg-scolor text-white transition-all duration-300 ease-in-out hover:bg-fcolor"
      >
        exemples
      </Link>
      <h1
        id="2"
        data-aos="zoom-out"
        className="text-3xl sm:text-5xl w-fit pb-5 mb-[100px] mx-auto text-fcolor font-bold relative before:w-[35%] before:absolute before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:h-[4px] before:bg-scolor "
      >
        Conception Graphique
      </h1>
      <div
        data-aos="fade-up"
        className="flex items-start md:items-center flex-wrap mb-[70px] px-3 md:px-9"
      >
        <div data-aos="fade-right" className="w-full  md:w-[47%] mb-7 md:mb-0">
          <img src={gd1} alt="" className="w-full h-full  rounded-2xl" />
        </div>
        <div
          data-aos="fade-left"
          className="w-full md:w-[53%] px-5 pt-0 md:pt-3"
        >
          <h2 className=" text-xl md:text-start mx-auto md:mx-0 text-center md:text-2xl  font-bold mb-10 text-scolor w-fit pb-3 relative before:absolute before:w-[50%] before:h-[5px] before:rounded-3xl  before:bottom-0 before:left-[50%] before:translate-x-[-50%]  md:before:translate-x-0 md:before:left-0 before:bg-fcolor">
            Qu'est-ce que le graphisme ?
          </h2>
          <p className="text-lg leading-relaxed  text-center md:text-start text-slate-600">
            La conception graphique est une étape centrale. L'impression a un
            coût certain, il ne faut donc pas prendre de risque au moment de la
            création. C'est pourquoi nous réunissons toutes nos compétences
            techniques, graphiques et communicationnelles pour réaliser un
            support des plus professionnels, que ce soit pour un flyer, pour une
            affiche ou même pour un logo. Notre équipe d'infographie, composée
            de designers créatifs aux styles variés, prend à c'ur la conception
            d'un visuel original et fiable.
          </p>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="flex items-start md:items-center flex-wrap mb-[50px] px-3 md:px-9"
      >
        <div
          data-aos="fade-right"
          className="w-full md:w-[53%] px-5 pt-3 mb-7 md:mb-0"
        >
          <p className="text-lg leading-relaxed text-center md:text-start  text-slate-800">
            DIGITAL GRAPHIC répond à tous vos besoins de conception graphique et
            entend vous apporter son expertise dans les domaines suivants:
          </p>
          <ul className=" flex flex-col gap-3 ps-8 mt-5 text-lg">
            <li className="text-fcolor relative before:absolute before:w-[12px] before:h-[12px] before:rounded-full before:bg-scolor before:left-[-20px] before:top-[50%] before:translate-y-[-50%] ">
              Design et conception de maquet graphique.
            </li>
            <li className="text-fcolor relative before:absolute before:w-[12px] before:h-[12px] before:rounded-full before:bg-scolor before:left-[-20px] before:top-[50%] before:translate-y-[-50%] ">
              Conception logo professionnelle.
            </li>
            <li className="text-fcolor relative before:absolute before:w-[12px] before:h-[12px] before:rounded-full before:bg-scolor before:left-[-20px] before:top-[50%] before:translate-y-[-50%] ">
              Création design publicitaire
            </li>
            <li className="text-fcolor relative before:absolute before:w-[12px] before:h-[12px] before:rounded-full before:bg-scolor before:left-[-20px] before:top-[50%] before:translate-y-[-50%] ">
              shooting réel de produit
            </li>
            <li className="text-fcolor relative before:absolute before:w-[12px] before:h-[12px] before:rounded-full before:bg-scolor before:left-[-20px] before:top-[50%] before:translate-y-[-50%] ">
              Design Web.
            </li>
          </ul>
        </div>
        <div data-aos="fade-left" className="w-full md:w-[47%]">
          <img src={gd2} alt="" className="w-full h-full   rounded-2xl" />
        </div>
      </div>
      <Link
        data-aos="zoom-in"
        to="/Work/ConceptionGraphique"
        className="flex w-[200px] mb-[100px] h-[50px] rounded-3xl mx-auto uppercase mt-6 justify-center items-center text-xl font-bold bg-scolor text-white transition-all duration-300 ease-in-out hover:bg-fcolor"
      >
        exemples
      </Link>
      {/*  */}
      <h1
        id="3"
        data-aos="zoom-out"
        className="text-3xl sm:text-5xl w-fit pb-5 mb-[100px] mx-auto text-fcolor font-bold relative before:w-[35%] before:absolute before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:h-[4px] before:bg-scolor "
      >
        Réseaux Sociaux
      </h1>
      <div
        data-aos="fade-up"
        className="flex items-start md:items-center flex-wrap mb-[70px] px-3 md:px-9"
      >
        <div data-aos="fade-right" className="w-full  md:w-[47%] mb-7 md:mb-0">
          <img
            src={socialMedia1}
            alt=""
            className="w-full h-full  rounded-2xl"
          />
        </div>
        <div
          data-aos="fade-left"
          className="w-full md:w-[53%] px-5 pt-0 md:pt-3"
        >
          <h2 className=" text-xl md:text-start text-center md:text-2xl mx-auto md:mx-0  font-bold mb-8 text-scolor w-fit pb-3 relative before:absolute before:w-[50%] before:h-[5px] before:rounded-3xl  before:bottom-0 before:left-[50%] before:translate-x-[-50%]  md:before:translate-x-0 md:before:left-0 before:bg-fcolor">
            Pourquoi utiliser les medias sociaux?
          </h2>
          <p className="text-lg leading-relaxed mb-5 text-center md:text-start text-slate-600">
            Véritable outil de marketing viral en complément des sites internet,
            les réseaux sociaux sont un formidable moyen de toucher plus de
            prospects. Ce sont des leviers efficaces pour booster la visibilité
            on-line de votre entreprise et augmenter le nombre de visiteurs sur
            votre site web.
          </p>
          <p className="text-lg leading-relaxed text-center md:text-start  text-slate-600">
            il et important de reconnaitre que toute entreprise a besoin d'une
            presence en ligne. Nous avons proposons un sercice de gestion de
            page adapte a vous besoins vous bénéficiez d'une startegie
            personnalisee, d'un contenu de qualite, d'une animation reguliere
            d'un sponsoring adapte et d'un suivi des performances.
          </p>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="flex flex-wrap items-start md:items-center mb-[50px] px-3 md:px-9"
      >
        <div
          data-aos="fade-right"
          className="w-full md:w-[53%] px-5 pt-3 mb-7 md:mb-0"
        >
          <p className="text-lg leading-relaxed text-center md:text-start text-slate-800">
            Les réseaux sociaux représentent aujourd’hui le premier point de
            contact d’une organisation avec un utilisateur et un client
            potentiel. Au premier coup d’œil, vous devez le convaincre de la
            pertinence et de la qualité de votre contenu pour l’inciter à aller
            plus loin. DIGITAL GRAPHIIC agence social media, crée pour vous une
            stratégie de communication précise et adaptée sur les réseaux
            sociaux.
          </p>
          <p className="mt-3 text-lg leading-relaxed  text-slate-800">
            ce que nous fournissons en tant que service dans ce cas :
          </p>
          <ul className=" flex flex-col gap-3 ps-8 mt-5 text-lg">
            <li className="text-fcolor relative before:absolute before:w-[12px] before:h-[12px] before:rounded-full before:bg-scolor before:left-[-20px] before:top-[50%] before:translate-y-[-50%] ">
              Gestion des réseaux
            </li>
            <li className="text-fcolor relative before:absolute before:w-[12px] before:h-[12px] before:rounded-full before:bg-scolor before:left-[-20px] before:top-[50%] before:translate-y-[-50%] ">
              Sponsoring
            </li>
          </ul>
        </div>
        <div data-aos="fade-left" className="w-full md:w-[47%]">
          <img src={sm2} alt="" className="w-full h-full   rounded-2xl" />
        </div>
      </div>

      <h1
        id="4"
        data-aos="zoom-out"
        className="text-3xl sm:text-5xl w-fit pb-5 mt-[50px]  mb-[100px] mx-auto text-fcolor font-bold relative before:w-[35%] before:absolute before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:h-[4px] before:bg-scolor "
      >
        Maintenance Informatique
      </h1>
      <div
        data-aos="fade-up"
        className="flex flex-wrap items-start md:items-center mb-[70px] px-3 md:px-9 "
      >
        <div data-aos="fade-right" className="w-full  md:w-[47%] mb-7 md:mb-0">
          <img src={cm1} alt="" className="w-full h-full  rounded-2xl" />
        </div>
        <div
          data-aos="fade-left"
          className="w-full md:w-[53%] px-5 pt-0 md:pt-3"
        >
          <h2 className=" text-xl md:text-start text-center md:text-2xl  font-bold mb-10 text-scolor w-fit pb-3 relative before:absolute before:w-[50%] before:h-[5px] before:rounded-3xl  before:bottom-0 before:left-[50%] before:translate-x-[-50%]  md:before:translate-x-0 md:before:left-0 before:bg-fcolor">
            Que couvre la maintenance informatique ?
          </h2>
          <ul className=" flex flex-col gap-3 ps-8 mt-5 text-lg">
            <li className="text-fcolor relative before:absolute before:w-[12px] before:h-[12px] before:rounded-full before:bg-scolor before:left-[-20px] before:top-[50%] before:translate-y-[-50%] ">
              Problèmes matérielle et/ou logicielle réseau tous types.
            </li>
            <li className="text-fcolor relative before:absolute before:w-[12px] before:h-[12px] before:rounded-full before:bg-scolor before:left-[-20px] before:top-[50%] before:translate-y-[-50%] ">
              Dépannages et entretiens de serveurs tous types.
            </li>
            <li className="text-fcolor relative before:absolute before:w-[12px] before:h-[12px] before:rounded-full before:bg-scolor before:left-[-20px] before:top-[50%] before:translate-y-[-50%] ">
              Dépannages et entretiens des ordinateurs
            </li>
            <li className="text-fcolor relative before:absolute before:w-[12px] before:h-[12px] before:rounded-full before:bg-scolor before:left-[-20px] before:top-[50%] before:translate-y-[-50%] ">
              Pannes matérielles sur pc et/ou serveurs.
            </li>
          </ul>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="flex items-start md:items-center flex-wrap mb-[50px] px-3 md:px-9"
      >
        <div
          data-aos="fade-right"
          className="w-full md:w-[53%] px-5 pt-3 mb-7 md:mb-0"
        >
          <p className="text-lg leading-relaxed text-center md:text-start text-slate-800">
            Nous vous conseillons de profiter de notre service de dépannage
            informatique lorsqu’un problème affecte votre système informatique,
            votre capacité à travailler n’est pas perturbée. Nous pouvons
            envoyer des techniciens expérimentés à votre entreprise
            immédiatement, qui peuvent vous aider et vous fournir une solution
            personnalisée.
          </p>
          <p className="text-lg leading-relaxed mt-3 text-center md:text-start text-slate-800">
            En faisant appel à notre service, vous améliorez votre système
            informatique en bénéficiant de nos prestations en installation de
            nouveaux logiciels, en optimisation de matériel, en nettoyage de
            disque et encore plus lors d’un dépannage informatique
          </p>
        </div>
        <div data-aos="fade-left" className="w-full md:w-[47%]">
          <img src={cm2} alt="" className="w-full h-full   rounded-2xl" />
        </div>
      </div>
    </div>
  );
}

export default Services;
