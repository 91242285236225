import React, { useCallback, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { MdDesignServices } from "react-icons/md";
import { RiInstagramFill, RiMoneyPoundCircleFill } from "react-icons/ri";
import { FaFacebook, FaLaptopCode } from "react-icons/fa";
import { motion } from "framer-motion";
import img1 from "../assets/hero/professional-programmer-working-late-dark-office.jpg";
import img2 from "../assets/hero/emily-bernal-v9vII5gV8Lw-unsplash.jpg";
import img3 from "../assets/hero/adem-ay-Tk9m_HP4rgQ-unsplash.jpg";
import img4 from "../assets/hero/technician-repairing-computer-computer-hardware-repairing-upgrade-technology.jpg";

///////
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "./swiper.css";
import { Swiper, SwiperSlide } from "swiper/react";
const data = [
  {
    id: 1,
    title: "CRÉATIONS GRAPHIC",
    par: "Notre studio graphique créera votre identité visuelle pour donner une image à votre entreprise ou a votre marque.",
    but: "Work/ConceptionGraphique",
    img: img2,
  },
  {
    id: 2,
    title: "Développement Web",
    par: "Nous développons des sites web professionnels, uniques et faciles à gérer .",
    but: "Work/WebDevelopment",
    img: img1,
  },
  {
    id: 3,
    title: "SOCIAL MÉDIA",
    par: "Nous vous accompagnons pour promouvoir et faire connaître votre marque",
    but: "Services",
    img: img3,
  },
  {
    id: 4,
    title: "Maintenance Informatique",
    par: "Vous Bénéficiez d’un véritable service de maintenance informatique pour votre entreprise.",
    but: "Services",
    img: img4,
  },
];

function Hero() {
  ////counter for geting data from the array
  const [counter, setCounter] = useState(0);
  //// data to disply /////
  const [text, setText] = useState(data[0]);

  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * data.length);
    setText(data[index]);
    setCounter(data[index].id);
  }, []);

  useEffect(() => {
    const intervalID = setInterval(shuffle, 5000);
    return () => clearInterval(intervalID);
  }, [shuffle]);

  return (
    <div className="w-full h-[100vh]">
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        modules={[EffectFade, Autoplay]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        className="mySwiper"
      >
        {data.map((ele) => {
          return (
            <SwiperSlide key={ele.id} className="w-full h-full relative">
              <div className="w-full h-full z-1 bg-[#00000073] absolute"></div>
              <img
                src={ele.img}
                alt=""
                className="w-full h-full object-cover "
              />
              <div className="w-full text-white h-full  absolute top-0 left-0 flex justify-center items-center  px-3 md:px-9  pt-[70px]">
                <div className="w-fit gap-7 h-fit flex flex-col justify-center items-center">
                  <h1 className=" text-scolor  text-[28px] sm:text-[50px] font-bold  tracking-wider  gap-4">
                    {ele.title}
                  </h1>
                  <p className=" text-[18px] sm:text-[21px] max-w-[500px] leading-10">
                    {ele.par}
                  </p>
                  <Link
                    to={ele.but}
                    className="w-[150px] h-[50px] text-lg sm:m-0 m-auto border border-scolor rounded-lg flex justify-center items-center font-bold hover:border-white hover:bg-white hover:text-scolor transition-all duration-300 ease-in-out"
                  >
                    VOIR PLUS...
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

export default Hero;
