import React, { useEffect, useState } from "react";
import bg from "../assets/eq.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link, NavLink, Route, Routes } from "react-router-dom";
import { Csp, ToUp, Wd, WorkAll } from "../components/index";
import { getDate } from "../fireBase/fetchFirebase";
function Work() {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className=" overflow-hidden pb-20 ">
      <ToUp />
      <div className=" w-full   flex items-center relative overflow-hidden h-[60vh] mb-[50px]">
        <div className=" absolute top-0 left-0 w-full h-full z-10 bg-[#0000007a]"></div>
        <div className="flex items-center justify-center flex-col gap-5 px-3 md:px-9  pt-[70px] absolute top-0 left-0 w-full h-full z-20">
          <h1
            data-aos="zoom-out"
            className="text-3xl sm:text-7xl w-fit pb-5  mx-auto text-white font-bold relative before:w-[50%] before:absolute before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:h-[4px] before:bg-scolor "
          >
            Tous Les Projets
          </h1>
          {/* <p
            data-aos="zoom-in"
            className="text-center max-w-[600px] text-white text-lg leading-relaxed font-semibold"
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati
            fugit quo qui ea iste perferendis saepe velit ratione, reprehenderit
            quis, nisi, quisquam veritatis quam aliquam eius ipsam provident
            deserunt nulla.
          </p> */}
          <p
            data-aos="zoom-in"
            className="text-center max-w-[600px] text-white text-md md:text-lg leading-relaxed font-semibold"
          >
            Notre travail est axé sur le développement de solutions innovantes
            qui répondent aux problèmes complexes rencontrés par les entreprises
            et les particuliers. Nous croyons qu'en tirant parti des
            technologies émergentes et en adoptant une approche centrée sur
            l'utilisateur, nous pouvons obtenir des résultats percutants qui
            entraînent un changement positif.
          </p>
        </div>
        <img src={bg} alt="" className="w-full h-full object-cover" />
      </div>
      <div className="px-3 md:px-9">
        <div className="flex justify-center items-center ">
          <ul className="flex flex-wrap md:justify-start justify-center items-center gap-5 text-lg md:text-2xl font-semibold">
            <li className="transition-all hover:text-scolor duration-200 ease-in-out">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? " text-scolor"
                    : "  text-black transition-all hover:text-scolor duration-200 ease-in-out"
                }
                to="All"
              >
                Projets
              </NavLink>
            </li>
            <li className="transition-all hover:text-scolor duration-200 ease-in-out">
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? " text-scolor"
                    : " transition-all hover:text-scolor duration-200 ease-in-out  text-black"
                }
                to="WebDevelopment"
              >
                Web Development
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? " text-scolor"
                    : " transition-all hover:text-scolor duration-200 ease-in-out  text-black"
                }
                to="ConceptionGraphique"
              >
                Conception Graphique
              </NavLink>
            </li>
          </ul>
        </div>
        <div className=" mt-[40px] md:mt-[80px] min-h-[50vh]">
          <Routes>
            <Route path="All" element={<WorkAll />} />
            <Route path="WebDevelopment" element={<Wd d />} />
            <Route path="ConceptionGraphique" element={<Csp />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Work;
