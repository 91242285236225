import {
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { app, db } from "../firebaseConfig";
export const getDate = async () => {
  const items = await getDocs(
    query(collection(db, "project"), orderBy("id", "desc"))
  );
  return items.docs.map((doc) => doc.data());
};
