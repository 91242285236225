import React from "react";
import { Link } from "react-router-dom";

function WorkSingel({ data }) {
  return (
    <Link
      to={`/productInfo/${data.id}`}
      data-aos="fade-in"
      className="w-full overflow-hidden  border shadow-xl h-[300px] relative group cursor-pointer rounded-xl transition-all duration-300 hover:drop-shadow-xl"
    >
      <div className="w-full h-full relative overflow-hidden">
        <img src={data.mainImg} alt="" className="w-full h-full object-fill" />
        <img
          src={data.mainImg}
          alt=""
          className=" scale-125 object-cover w-full h-full transition-all duration-300 ease-in-out absolute top-0 left-0 -z-50 opacity-0 group-hover:z-50 group-hover:opacity-100"
        />
      </div>
      <div className="w-full hidden md:flex text-white  h-full absolute bottom-[-80%] p-5 left-0 bg-[#00203fc7] group-hover:bottom-0 z-[10000] transition-all duration-300 ease-in-out  flex-col justify-between items-center">
        <div className="flex justify-between items-center w-full">
          <p className="text-lg">Titre :</p>
          <p className="text-xl font-bold">{data.title}</p>
        </div>
        <h1 className=" uppercase font-bold">cliquez...</h1>
        <div className="flex justify-between items-center w-full">
          <p className="text-lg">Catégorie :</p>
          <p className="text-xl font-bold">{data.category}</p>
        </div>
      </div>
      <div className="w-full black md:hidden text-white  h-[80px] absolute bottom-[0%] py-2 px-3 left-0 bg-[#00203fc7] z-[1000]  flex-col justify-between items-center">
        <div className="flex justify-between itmes-center mb-2">
          <p className="text-lg font-bold">{data.title}</p>
          <p className="text-lg font-bold">{data.category}</p>
        </div>
        <p className="w-full text-center text-xl font-bold group-hover:text-scolor uppercase transition-all duration-300 ease-in-out">
          voir plus...
        </p>
      </div>
    </Link>
  );
}

export default WorkSingel;
