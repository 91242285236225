import React, { useState } from "react";
// import logo from "../assets/logo digital graphic.png";
import { FaBars, FaHome, FaQuestion } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import {
  MdClose,
  MdFacebook,
  MdMessage,
  MdMiscellaneousServices,
  MdWork,
} from "react-icons/md";

import logo from "../assets/d.png";
import { Link, NavLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
function Navbar() {
  const [color, setcolor] = useState(false);
  const [show, setShow] = useState(false);
  window.addEventListener("scroll", function (e) {
    if (this.scrollY > 80) {
      setcolor(true);
    } else if (show) {
      setcolor(true);
    } else {
      setcolor(false);
    }
  });
  return (
    /////////////////////////////////Desktop/////////////////////////////////////
    <nav
      className={`h-[70px] w-full fixed top-0 right-0 z-40 px-3 md:px-9 transition-all duration-500 ease-in-out ${
        color ? "bg-fcolor drop-shadow-xl" : "bg-transparent"
      }`}
    >
      <div className=" justify-between items-center h-full w-full hidden sm:flex">
        <Link to="/" className="flex items-center group gap-[2px]  ">
          <div className=" w-[50px]">
            <img src={logo} alt="logo" className="w-full h-full object-cover" />
          </div>
          <h1
            className={`text-xl font-semibold duration-300 ease-in-out transition-all ${
              color
                ? "text-white group-hover:text-scolor"
                : " text-white group-hover:text-scolor"
            } `}
          >
            igital Graphic
          </h1>
        </Link>
        <ul
          className={`flex items-center justify-between gap-6 text-xl font-semibold text-white`}
        >
          <li
            className={`transition-all duration-300 ease-in-out h-fit w-fit text-white hover:text-scolor`}
          >
            <NavLink
              className={({ isActive }) => (isActive ? "text-scolor" : "")}
              to="/"
            >
              Accueil
            </NavLink>
          </li>
          <li
            className={`transition-all duration-300 ease-in-out h-fit w-fit text-white hover:text-scolor`}
          >
            <NavLink
              className={({ isActive }) => (isActive ? "text-scolor" : "")}
              to="Services"
            >
              Services
            </NavLink>
          </li>
          <li
            className={`transition-all duration-300 ease-in-out h-fit w-fit text-white hover:text-scolor`}
          >
            <NavLink
              className={({ isActive }) => (isActive ? "text-scolor" : "")}
              to="Work/All"
            >
              Projets
            </NavLink>
          </li>
          <li
            className={`transition-all duration-300 ease-in-out h-fit w-fit text-white hover:text-scolor`}
          >
            <NavLink
              to="About"
              className={({ isActive }) => (isActive ? "text-scolor" : "")}
            >
              À propos de nous
            </NavLink>
          </li>
          <li
            className={`transition-all duration-300 ease-in-out h-fit w-fit text-white hover:text-scolor`}
          >
            <NavLink
              className={({ isActive }) => (isActive ? "text-scolor" : "")}
              to="Contact"
            >
              Contactez-nous
            </NavLink>
          </li>
        </ul>
      </div>
      {/* //////////////////////////////// mobile ////////////////////////////// */}
      <div className="justify-between items-center h-full w-full flex sm:hidden relative">
        <Link to="/" className="flex items-center group gap-[2px]  ">
          <div className=" w-[40px]">
            <img src={logo} alt="logo" className="w-full h-full object-cover" />
          </div>
          <h1
            className={`text-lg font-semibold duration-300 ease-in-out transition-all text-white group-hover:text-scolor`}
          >
            igital Graphic
          </h1>
        </Link>
        <div
          onClick={() => {
            setShow(!show);
            setcolor(true);
          }}
        >
          {show ? (
            <motion.div
              transition={{ ease: "easeOut", duration: 0.9 }}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
            >
              <MdClose
                className={`text-3xl
                text-white cursor-pointer hover:text-scolor duration-300 ease-in-out transition-all`}
              />
            </motion.div>
          ) : (
            <FaBars
              className={`text-3xl
                text-white cursor-pointer hover:text-scolor duration-300 ease-in-out transition-all`}
            />
          )}
        </div>
        <AnimatePresence>
          {show && (
            <motion.div
              transition={{ ease: "easeInOut", duration: 0.9 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className=" absolute w-[calc(100%+24px)]  bg-fcolor top-[70px] -left-3 h-[calc(100vh-70px)] flex flex-col  justify-evenly px-5 "
            >
              <ul
                className={`flex flex-col  gap-8 text-[22px] font-semibold text-white`}
              >
                <motion.li
                  onClick={() => setShow(false)}
                  transition={{ ease: "linear", duration: 0.6 }}
                  initial={{ paddingLeft: "30px" }}
                  animate={{ paddingLeft: 0 }}
                  exit={{ paddingLeft: "30px" }}
                  className={`transition-all duration-300 ease-in-out h-fit w-fit text-white hover:text-scolor flex items-center gap-3 `}
                >
                  <FaHome className="text-[22px]" />
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "  text-scolor" : ""
                    }
                    to="/"
                  >
                    Accueil
                  </NavLink>
                </motion.li>
                <motion.li
                  onClick={() => setShow(false)}
                  transition={{ ease: "linear", duration: 0.6, delay: 0.2 }}
                  initial={{ paddingLeft: "50px" }}
                  animate={{ paddingLeft: 0 }}
                  exit={{ paddingLeft: "50px" }}
                  className={`transition-all duration-300 ease-in-out h-fit w-fit text-white hover:text-scolor  flex items-center gap-3 `}
                >
                  <MdMiscellaneousServices className="text-[22px]" />
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "text-scolor" : ""
                    }
                    to="Services"
                  >
                    Services
                  </NavLink>
                </motion.li>
                <motion.li
                  onClick={() => setShow(false)}
                  transition={{ ease: "linear", duration: 0.6, delay: 0.4 }}
                  initial={{ paddingLeft: "50px" }}
                  animate={{ paddingLeft: 0 }}
                  exit={{ paddingLeft: "50px" }}
                  className={`transition-all duration-300 ease-in-out h-fit w-fit text-white hover:text-scolor  flex items-center gap-3 `}
                >
                  <MdWork className="text-[22px]" />
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "text-scolor" : ""
                    }
                    to="Work/All"
                  >
                    Projets
                  </NavLink>
                </motion.li>
                <motion.li
                  onClick={() => setShow(false)}
                  transition={{ ease: "linear", duration: 0.6, delay: 0.6 }}
                  initial={{ paddingLeft: "50px" }}
                  animate={{ paddingLeft: 0 }}
                  exit={{ paddingLeft: "50px" }}
                  className={`transition-all duration-300 ease-in-out h-fit w-fit text-white hover:text-scolor  flex items-center gap-3 `}
                >
                  <FaQuestion className="text-[22px]" />
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "text-scolor" : ""
                    }
                    to="About"
                  >
                    À propos de nous
                  </NavLink>
                </motion.li>
                <motion.li
                  onClick={() => setShow(false)}
                  transition={{ ease: "linear", duration: 0.6, delay: 0.8 }}
                  initial={{ paddingLeft: "50px" }}
                  animate={{ paddingLeft: 0 }}
                  exit={{ paddingLeft: "50px" }}
                  className={`transition-all duration-300 ease-in-out h-fit w-fit text-white hover:text-scolor  flex items-center gap-3 `}
                >
                  <MdMessage className="text-[22px]" />
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "text-scolor" : ""
                    }
                    to="Contact"
                  >
                    Contactez-nous
                  </NavLink>
                </motion.li>
              </ul>
              <div className="h-[80px] w-full flex justify-center items-end">
                <ul className="flex justify-evenly items-center w-full">
                  <motion.li
                    transition={{ ease: "linear", duration: 0.6, delay: 0.6 }}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                  >
                    <a
                      href="https://www.facebook.com/profile.php?id=100064091171195&mibextid=ZbWKwL"
                      target="_blank"
                      className="text-white hover:text-scolor transition-all duration-300 ease-in-out "
                      rel="noreferrer"
                    >
                      <MdFacebook className="text-3xl" />
                    </a>
                  </motion.li>
                  {/* <motion.li
                    transition={{ ease: "linear", duration: 0.6, delay: 0.6 }}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                  >
                    <a
                      href="/"
                      className="text-white hover:text-scolor transition-all duration-300 ease-in-out "
                    >
                      <MdEmail className="text-3xl" />
                    </a>
                  </motion.li> */}
                  <motion.li
                    transition={{ ease: "linear", duration: 0.6, delay: 0.6 }}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                  >
                    <a
                      href="https://instagram.com/digitalgraphe?igshid=MWZjMTM2ODFkZg=="
                      rel="noreferrer"
                      target="_blank"
                      className="text-white hover:text-scolor transition-all duration-300 ease-in-out "
                    >
                      <RiInstagramFill className="text-3xl" />
                    </a>
                  </motion.li>
                </ul>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
}

export default Navbar;
