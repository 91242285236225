import React, { useEffect, useState, useRef } from "react";
import contact from "../assets/hot-line-contactez-nous-centre-appels-interface-recherche (1).jpg";
import smart from "../assets/icons8-smartphone-et-tablette-94.png";
import social from "../assets/icons8-message-chat-94.png";
import email from "../assets/icons8-email-94.png";
import { MdEmail, MdFacebook } from "react-icons/md";
import { RiInstagramFill } from "react-icons/ri";
import contactus from "../assets/contactUs.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
function ContactUs() {
  const [name, setName] = useState("");
  const [emailit, setEmailit] = useState("");
  const [subject, setSubject] = useState("");
  const form = useRef();
  const sendit = (e) => {
    e.preventDefault();
    if (
      name !== "" &&
      name !== " " &&
      emailit !== "" &&
      emailit !== " " &&
      subject !== "" &&
      subject !== " "
    ) {
      emailjs
        .sendForm(
          "service_cckiwwu",
          "template_zlk4u3k",
          form.current,
          "A1ff_6UG_qqhGqNmq"
        )
        .then(
          (result) => {
            setName("");
            setEmailit("");
            setSubject("");
            toast.success("Votre email a été envoyé 👍", {
              position: "top-center",
              theme: "dark",
              autoClose: 2000,
            });
          },
          (error) => {
            toast.error("Quelque chose s'est mal passé 👎", {
              position: "top-center",
              theme: "dark",
              autoClose: 2000,
            });
          }
        );
    } else {
      toast.error("veuillez remplir tous les champs 👎", {
        position: "top-center",
        theme: "dark",
        autoClose: 2000,
      });
    }
  };
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className=" overflow-hidden ">
      <div className="   px-3 md:px-9 w-full  flex items-center relative overflow-hidden h-[60vh]">
        <div className="w-full -z-20 h-full absolute top-0 left-0 bg-fcolor"></div>
        <div className="w-full z-[-1] block md:hidden h-full absolute top-0 left-0 bg-[#00000061]"></div>
        <div
          data-aos="fade-left"
          className=" -z-10 w-full md:w-[50%]  h-full right-0 top-0 absolute md:rounded-tl-[150px] md:rounded-bl-[300px] overflow-hidden"
        >
          <img
            src={contact}
            alt=""
            className="w-full h-full object-cover object-top"
          />
        </div>
        <div className="w-[250px] h-[250px] -z-10 cust absolute bottom-[-100px] left-[50%] translate-x-[-50%] hidden md:block bg-scolor"></div>
        <div className=" relative w-full md:w-[50%] md:text-start text-center">
          <h1
            data-aos="zoom-in"
            className="text-5xl text-white md:text-scolor mb-10 font-bold"
          >
            Entrer en contact
          </h1>
          <p
            data-aos="fade-right"
            className="text-xl text-white mx-auto md:mx-0 max-w-[450px]"
          >
            Vous souhaitez entrer en contact? Nous aimerions avoir de vos
            nouvelles. Voici comment vous pouvez nous joindre...
          </p>
        </div>
      </div>
      <div className="px-3 md:px-9 mt-[-35px] z-50 h-fit lg:h-[350px]   mb-[30px]">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 ">
          <motion.div
            key={1}
            transition={{ duration: 2, ease: "easeInOut" }}
            initial={{ height: 0, padding: 0 }}
            animate={{ height: "350px", padding: "16px" }}
            exit={{ height: 0 }}
            className="w-full  overflow-hidden bg-white shadow-lg shadow-slate-700 rounded-xl flex flex-col  gap-3"
          >
            <div className="flex justify-center items-center ">
              <img
                src={smart}
                alt=""
                className="w-[60px] h-[60px] object-cover"
              />
            </div>
            <h1 className="text-center font-bold text-3xl text-fcolor">
              APPELEZ-NOUS
            </h1>
            <p className="text-lg text-center">
              Intéressé par notre travail? Appelez-nous simplement pour en
              savoir plus sur nos services et nos prix
            </p>
            <div className="flex justify-between gap-2 items-center">
              <span className="text-[17px]  font-bold ">
                R. Développement web
              </span>
              <p className="text-center text-[17px]  text-scolor font-bold">
                07 76 12 85 24
              </p>
            </div>
            <div className="flex justify-between gap-2 items-center">
              <span className="text-[17px]  font-bold ">
                R. Conception graphique
              </span>
              <p className="text-center text-[17px]  text-scolor font-bold">
                05 61 60 21 56
              </p>
            </div>
            <div className="flex justify-between gap-2 items-center">
              <span className="text-[17px]  font-bold ">
                G. Réseaux sociaux
              </span>
              <p className="text-center text-[17px]  text-scolor font-bold">
                06 64 96 02 92
              </p>
            </div>
            {/* <p className="text-center text-xl text-scolor font-bold">
              05 61 60 21 56
            </p>
            <p className="text-center text-xl text-scolor font-bold">
              06 64 96 02 92
            </p> */}
          </motion.div>
          <motion.div
            key={2}
            transition={{ duration: 2, ease: "easeInOut" }}
            initial={{ height: 0, padding: 0 }}
            animate={{ height: "350px", padding: "16px" }}
            exit={{ height: 0 }}
            className="w-full overflow-hidden  bg-white shadow-lg shadow-slate-700 rounded-xl flex flex-col gap-3"
          >
            <div className="flex justify-center items-center ">
              <img
                src={social}
                alt=""
                className="w-[60px] h-[60px] object-cover"
              />
            </div>
            <h1 className="text-center font-bold text-3xl text-fcolor uppercase">
              Réseaux Sociaux
            </h1>
            <p className="text-lg text-center">
              Vous pouvez nous suivre pour les choses à venir, ou nous contacter
              sur nos réseaux sociaux
            </p>
            <a
              href="https://www.facebook.com/profile.php?id=100064091171195&mibextid=ZbWKwL"
              target="_blank"
              rel="noreferrer"
              className=" text-xl font-bold flex items-center gap-3 text-scolor hover:text-fcolor justify-center transition-all duration-300 ease-in-out "
            >
              <MdFacebook className="text-2xl" />
              Digital Graphic
            </a>
            <a
              href="https://instagram.com/digitalgraphe?igshid=MWZjMTM2ODFkZg=="
              target="_blank"
              rel="noreferrer"
              className=" text-xl font-bold flex items-center gap-3 hover:text-fcolor text-scolor justify-center transition-all duration-300 ease-in-out "
            >
              <RiInstagramFill className="text-2xl" />
              Digital Graphic
            </a>
          </motion.div>
          <motion.div
            key={3}
            transition={{ duration: 2, ease: "easeInOut" }}
            initial={{ height: 0, padding: 0 }}
            animate={{ height: "350px", padding: "16px" }}
            exit={{ height: 0 }}
            className="w-full overflow-hidden bg-white shadow-lg shadow-slate-700 rounded-xl flex flex-col  gap-3"
          >
            <div className="flex justify-center items-center ">
              <img
                src={email}
                alt=""
                className="w-[60px] h-[60px] object-cover"
              />
            </div>
            <h1 className="text-center font-bold text-3xl uppercase text-fcolor">
              Envoyez un e-mail
            </h1>
            <p className="text-lg text-center">
              pour une manière plus professionnelle. vous pouvez nous envoyer un
              email et nous ferons de notre mieux pour vous répondre aussi vite
              que possible
            </p>
            <p className="text-center text-xl text-scolor font-bold flex items-center justify-center gap-3">
              <MdEmail />
              digitalgraphicdz@gmail.com
            </p>
          </motion.div>
        </div>
      </div>
      <div className=" px-3 md:px-9 w-full mt-20 mb-20">
        <h1
          data-aos="zoom-out"
          className="text-3xl sm:text-5xl w-fit pb-5 mb-[100px] mx-auto text-fcolor font-bold relative before:w-[50%] before:absolute before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:h-[4px] before:bg-scolor "
        >
          E-mail Rapide
        </h1>
        <div className="w-full flex flex-wrap ">
          <div data-aos="fade-right" className="w-full xl:w-[50%]">
            <img
              src={contactus}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <div data-aos="fade-left" className="w-full xl:w-[50%] ">
            <div className="bg-scolor w-[95%] mx-auto rounded-3xl p-[14px] shadow-xl">
              <h1 className=" capitalize text-center text-2xl sm:text-3xl text-white font-bold mb-2">
                envoyez - nous un e-mail
              </h1>
              <form ref={form} onSubmit={(e) => sendit(e)} className="w-full ">
                <label className="block mb-2">
                  <span className=" text-fcolor font-bold after:content-['*'] after:ml-0.5 after:text-red-500 block text-lg  ">
                    Name
                  </span>
                  <input
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    type="text"
                    name="to_name"
                    className="mt-1 px-3 py-3 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-fcolor focus:ring-fcolor block w-full rounded-md sm:text-lg focus:ring-1"
                    placeholder="Veuillez entrer votre nom"
                  />
                </label>
                <label className="block mb-2">
                  <span className="text-fcolor font-bold after:content-['*'] after:ml-0.5 after:text-red-500 block text-lg  ">
                    Email
                  </span>
                  <input
                    onChange={(e) => setEmailit(e.target.value)}
                    value={emailit}
                    type="email"
                    name="from_name"
                    className=" peer  mt-1 px-3 py-3 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-fcolor focus:ring-fcolor block w-full rounded-md sm:text-lg focus:ring-1"
                    placeholder="you@example.com"
                  />
                  <p className="mt-1 invisible peer-invalid:visible text-white text-md">
                    Veuillez fournir une adresse e-mail valide.
                  </p>
                </label>
                <label className="block mb-3">
                  <span className="text-fcolor font-bold after:content-['*'] after:ml-0.5 after:text-red-500 block text-lg  ">
                    Message
                  </span>
                  <textarea
                    onChange={(e) => setSubject(e.target.value)}
                    value={subject}
                    name="message"
                    className="w-full h-[100px] rounded-md sm:text-lg resize-none p-2  mt-1 px-3 py-3 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-fcolor focus:ring-fcolor block  focus:ring-1"
                    placeholder="Saisissez votre message"
                  />
                </label>
                <button
                  type="submit"
                  className=" text-lg font-bold transition-all duration-300 ease-in-out hover:bg-white hover:text-fcolor w-[200px] h-[40px] rounded-2xl bg-fcolor text-white mx-auto flex items-center justify-center"
                >
                  Envoyer
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
