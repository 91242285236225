import React, { useEffect } from "react";
import smile from "../assets/icons8-smiling-face-with-heart-eyes-94.png";
import cupe from "../assets/icons8-trophée-94.png";
import suc from "../assets/icons8-verrouiller-2-94.png";
import p1 from "../assets/services/p1.jpg";
import p2 from "../assets/services/p2.jpg";
import p3 from "../assets/services/p3.jpg";
import p4 from "../assets/services/p4.jpg";
import p5 from "../assets/services/p5.jpg";
// import Swiper JS
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
// import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./swiper.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
const data = [
  {
    title: "Web Development",
    img: p1,
  },
  {
    title: "Gestion des réseaux sociaux",
    img: p3,
  },
  {
    title: "Conception de l'emballage",
    img: p5,
  },
  {
    title: "Logo design",
    img: p4,
  },
  {
    title: "sponsoring",
    img: p2,
  },
];
function WhatweDo() {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);
  return (
    <div className="w-full min-h-[100vh] sm:min-h-[130vh]    pt-[50px]  lg:mb-0 mb-[100px] overflow-hidden  px-3 md:px-9">
      <h1
        data-aos="zoom-out"
        className="text-3xl sm:text-5xl w-fit pb-5 mb-[100px] mx-auto text-fcolor font-bold relative before:w-[50%] before:absolute before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:h-[4px] before:bg-scolor "
      >
        Ce Que Nous Faisons
      </h1>
      <div className="flex flex-wrap  gap-10 lg:gap-0">
        <div
          data-aos="fade-right"
          // data-aos-offset="300"
          // data-aos-easing="ease-in-sine"
          className="w-[100%] lg:w-[50%] relative flex items-center justify-center "
        >
          <div className=" absolute hidden sm:flex right-[50px] rounded-xl bottom-[100px] opacity-[0.6] w-[30px] h-[30px] shadow-lg shadow-slate-400  items-center justify-center z-10 bg-white"></div>
          <div className=" absolute left-[50px] hidden sm:flex rounded-xl top-[120px]  opacity-[0.6]  w-[50px] h-[50px] shadow-lg shadow-slate-400  items-center justify-center z-10 bg-white"></div>
          <div className="w-full h-full absolute top-0 left-0 -z-10 before:absolute before:top-0 before:left-14   before:w-[250px] before:h-[250px] before:rounded-full before:bg-scolor before:blur-3xl after:absolute after:bottom-0 after:right-12   after:w-[250px] after:h-[250px] after:rounded-full after:bg-scolor after:blur-3xl"></div>
          <div className="sm:w-[400px] w-[300px] h-[380px] sm:h-[480px] bg-white shadow-2xl rounded-xl relative flex justify-center items-center">
            <div className=" absolute top-[-40px] rounded-xl left-[50%] translate-x-[-50%] sm:w-[80px] w-[55px] h-[55px] sm:h-[80px] shadow-lg shadow-slate-400 flex items-center justify-center z-10 bg-white">
              <img
                src={smile}
                alt=""
                className=" object-cover w-[55px] h-[55px]"
              />
            </div>
            <div className=" absolute right-[-20px] sm:right-[-40px] rounded-xl top-[50%] translate-y-[-50%] sm:w-[80px] w-[55px] h-[55px] sm:h-[80px] shadow-lg shadow-slate-400 flex items-center justify-center z-10 bg-white">
              <img
                src={cupe}
                alt=""
                className=" object-cover w-[55px] h-[55px]"
              />
            </div>
            <div className=" absolute left-[-20px] sm:left-[-40px] rounded-xl bottom-[-20px]  sm:w-[80px] w-[55px] h-[55px] sm:h-[80px] shadow-lg shadow-slate-400 flex items-center justify-center z-10 bg-white">
              <img
                src={suc}
                alt=""
                className=" object-cover w-[55px] h-[55px]"
              />
            </div>
            <div className="w-[85%] h-[85%] ">
              <Swiper
                spaceBetween={0}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                {data.map((ele, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <img
                        src={ele.img}
                        alt=""
                        className=" rounded-[20px] w-full h-[88%] object-fill "
                      />
                      <h1 className="h-[10%] w-full border-t-4 border-scolor  flex justify-end items-center text-base sm:text-2xl font-semibold">
                        {ele.title}
                      </h1>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-left"
          // data-aos-offset="300"
          // data-aos-easing="ease-in-sine"
          className="w-[100%] lg:w-[50%] flex flex-col justify-between gap-5 lg:items-start items-center"
        >
          <h1 className=" text-scolor font-bold text-4xl text-center sm:text-start sm:text-5xl   relative before:absolute before:bottom-0 before:left-0 before:h-[5px] before:rounded-3xl before:w-[100px] before:bg-fcolor pb-5">
            service fourni par nous
          </h1>
          <p className=" text-xl text-gray-500 leading-loose w-full md:w-[550px] text-center lg:text-start ">
            notre entreprise est là pour simplifier votre expérience dans la
            création de votre propre produit ou site Web en fournissant un grand
            nombre de services qui peuvent vous faciliter la vie. voici quelques
            exemples et il y en a d'autres à explorer
          </p>
          <Link
            to="Services"
            className="w-full h-[60px] text-[25px] font-bold rounded-full text-white bg-fcolor flex justify-center items-center transition-all duration-300 ease-in-out hover:bg-scolor hover:text-fcolor"
          >
            VOIR PLUS ...
          </Link>
        </div>
      </div>
    </div>
  );
}

export default WhatweDo;
