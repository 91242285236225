import { AnimatePresence } from "framer-motion";
import { Navbar, Footer, ProductInfo } from "./components/index";
import { Route, Routes } from "react-router";
import { About, ContactUs, Home, Services, Work } from "./pages/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <>
      <AnimatePresence>
        <div className=" overflow-hidden">
          <ToastContainer />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="Contact" element={<ContactUs />} />
            <Route path="About" element={<About />} />
            <Route path="Services" element={<Services />} />
            <Route path="Work/*" element={<Work />} />
            <Route path="productInfo/:id" element={<ProductInfo />} />
          </Routes>
          <Footer />
        </div>
      </AnimatePresence>
    </>
  );
}

export default App;
