import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import wd from "../assets/features/p4.png";
import sm from "../assets/features/p1.png";
import gd from "../assets/features/p2.png";
import mi from "../assets/features/p3.png";

function Features() {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);
  return (
    <div className="w-full min-h-[100vh] xl:min-h-[96vh]  pt-[50px] xl:mb-0 mb-[100px]  px-3 md:px-9">
      <h1
        data-aos="zoom-out"
        className=" text-3xl sm:text-5xl w-fit pb-5 mb-[80px] mx-auto text-fcolor font-bold relative before:w-[50%] before:absolute before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:h-[4px] before:bg-scolor "
      >
        Nos Fonctionnalités
      </h1>
      <div
        data-aos="fade-up"
        className="grid sm:grid-cols-2 xl:grid-cols-4 gap-5 text-center"
      >
        <div className="w-full min-h-[350px]  rounded-lg bg-fcolor p-[15px] relative before:absolute before:w-full before:h-full before:left-0 before:-bottom-3 before:rounded-lg before:bg-scolor before:-z-10 hover:before:bottom-0 before:transition-all before:duration-300 before:ease-in-out hover:shadow-slate-500 hover:shadow-xl transition-all duration-300 ease-in-out">
          <div className=" mb-[15px] flex justify-center items-center">
            {/* <FaCode className="text-[60px] text-scolor" /> */}
            <img src={wd} alt="" className="w-[90px] h-[90px] " />
          </div>
          <p className="text-[24px] text-white font-bold mb-[15px]">
            création de site web
          </p>
          <p className="text-white text-[20px]">
            Responsive design, Bien conçu et fonctionnel, Facile à utiliser,
            Optimisé pour SEO, Styles uniques, Code optimisé
          </p>
        </div>
        <div className="w-full h-[350px] rounded-lg bg-fcolor p-[15px] relative before:absolute before:w-full before:h-full before:left-0 before:-bottom-3 before:rounded-lg before:bg-scolor before:-z-10 hover:before:bottom-0 before:transition-all before:duration-300 before:ease-in-out hover:shadow-slate-500 hover:shadow-xl transition-all duration-300 ease-in-out">
          {/* <div className="text-center mb-[15px] flex justify-center">
            <FaCode className="text-[60px] text-scolor" />
          </div> */}
          <div className=" mb-[15px] flex justify-center items-center">
            <img src={gd} alt="" className="w-[90px] h-[90px] " />
          </div>
          <p className="text-[24px] text-white font-bold mb-[15px] ">
            Creations graphic
          </p>
          <p className="text-white text-[20px]">
            Des designs graphiques de haute qualité qui mettront en valeur votre
            marque et captiveront votre public.
          </p>
        </div>
        <div className="w-full h-[350px] rounded-lg bg-fcolor p-[15px] relative before:absolute before:w-full before:h-full before:left-0 before:-bottom-3 before:rounded-lg before:bg-scolor before:-z-10 hover:before:bottom-0 before:transition-all before:duration-300 before:ease-in-out hover:shadow-slate-500 hover:shadow-xl transition-all duration-300 ease-in-out">
          {/* <div className="text-center mb-[15px] flex justify-center">
            <FaCode className="text-[60px] text-scolor" />
          </div> */}
          <div className=" mb-[15px] flex justify-center items-center">
            <img src={sm} alt="" className="w-[90px] h-[90px] " />
          </div>
          <p className="text-[24px] text-white font-bold mb-[15px]">
            Social Media
          </p>
          <p className="text-white text-[20px]">
            Gérez efficacement votre présence sur les réseaux sociaux avec nos
            services professionnels de gestion des réseaux sociaux.
          </p>
        </div>
        <div className="w-full h-[350px] rounded-lg bg-fcolor p-[15px] relative before:absolute before:w-full before:h-full before:left-0 before:-bottom-3 before:rounded-lg before:bg-scolor before:-z-10 hover:before:bottom-0 before:transition-all before:duration-300 before:ease-in-out hover:shadow-slate-500 hover:shadow-xl transition-all duration-300 ease-in-out">
          {/* <div className="text-center mb-[15px] flex justify-center">
            <FaCode className="text-[60px] text-scolor" />
          </div> */}
          <div className=" mb-[15px] flex justify-center items-center">
            <img src={mi} alt="" className="w-[90px] h-[90px] object-fill " />
          </div>
          <p className="text-[24px] text-white font-bold mb-[15px]">
            Maintenance Informatique
          </p>
          <p className="text-white text-[20px]">
            Notre service de maintenance informatique garantit des performances,
            une sécurité et une longévité optimales pour vos appareils.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Features;
